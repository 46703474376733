import { Skeleton } from "components/Skeleton";

export const CentreCardSkeleton = (): JSX.Element => (
    <li className="flex animate-pulse flex-col gap-2 overflow-hidden rounded-xl border-[0.5px] border-solid border-blue-grey-100 bg-white pb-3 pt-3 transition-all lg:gap-5 lg:pb-4 lg:pt-0">
        <div className="mx-3 h-3 w-32 rounded bg-blue-grey-50 lg:hidden" />
        <div className="mx-3 grid grid-cols-2 gap-2 lg:mx-0 lg:grid-cols-1">
            <div className="aspect-video w-full flex-1 bg-blue-grey-50" />
            <Skeleton className="lg:mx-4" title paragraph={{ rows: 2 }} />
        </div>
        <div className="mx-3 grid grid-cols-2 gap-2 lg:mx-4">
            <div className="h-7 rounded bg-blue-grey-50 lg:h-10" />
            <div className="h-7 rounded bg-blue-grey-50 lg:h-10" />
        </div>
    </li>
);
