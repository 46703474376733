import { cx } from "class-variance-authority";
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "components/Carousel";
import { CategoriesIcon } from "components/Icons/Categories";

export type FilterProps<T> = FilterOptions<T> & {
    selected: T;
    loading?: boolean;
    onChange: (f: T) => void;
};

export type FilterOptions<T> = {
    icon: string;
    label: string;
    value: T;
};

export type FilterRadioGroupProps<T> = {
    options: FilterOptions<T>[];
    selected: string;
    loading?: boolean;
    onChange: (f: T) => void;
};

export const FilterIcon = <T,>({
    label,
    value,
    icon,
    ...props
}: FilterProps<T>): JSX.Element => (
    <span
        className={cx(
            "flex w-max cursor-pointer flex-col items-center gap-2 border-0 border-b-2 border-solid px-1 pb-1 pt-2 transition-all hover:stroke-blue-grey-900 hover:text-blue-grey-900",
            value === props.selected
                ? "border-primary-300 stroke-blue-grey-900 text-blue-grey-900"
                : "border-transparent stroke-blue-grey-400 text-blue-grey-400",
            props.loading
                ? "animate-pulse cursor-not-allowed text-transparent"
                : "cursor-pointer",
        )}
        onClick={() => !props.loading && props.onChange(value)}
    >
        <CategoriesIcon type={icon} />
        {label}
    </span>
);

export const FilterIconsGroup = <T,>({
    scrollable,
    options,
    selected,
    loading = false,
    onChange,
}: {
    scrollable?: boolean;
    options: FilterOptions<T>[];
    selected: T;
    loading?: boolean;
    onChange: (f: T) => void;
}): JSX.Element => {
    if (!scrollable)
        return (
            <div className="flex gap-2">
                {options.map((prop, i) => (
                    <FilterIcon
                        key={i}
                        icon={prop.icon}
                        label={prop.label}
                        value={prop.value}
                        selected={selected}
                        onChange={onChange}
                    />
                ))}
            </div>
        );
    return (
        <div className="flex flex-col items-center">
            <Carousel
                opts={{
                    align: "start",
                    dragFree: true,
                }}
                className="w-[90%]"
            >
                <CarouselContent>
                    {options.map((prop, i) => (
                        <CarouselItem key={i}>
                            <FilterIcon
                                key={i}
                                icon={prop.icon}
                                label={prop.label}
                                value={prop.value}
                                selected={selected}
                                loading={loading}
                                onChange={onChange}
                            />
                        </CarouselItem>
                    ))}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
            </Carousel>
        </div>
    );
};
