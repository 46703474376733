import { SVGProps } from "./types";

export const OfficeBuildingUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15.4444 17V4.55556C15.4444 3.69645 14.748 3 13.8889 3H6.11111C5.252 3 4.55556 3.69645 4.55556 4.55556V17M15.4444 17L17 17M15.4444 17H11.5556M4.55556 17L3 17M4.55556 17H8.44444M7.66667 6.11109H8.44444M7.66667 9.2222H8.44444M11.5556 6.11109H12.3333M11.5556 9.2222H12.3333M8.44444 17V13.1111C8.44444 12.6815 8.79267 12.3333 9.22222 12.3333H10.7778C11.2073 12.3333 11.5556 12.6815 11.5556 13.1111V17M8.44444 17H11.5556"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
