import Link from "next/link";
import { graphql } from "lib/gql";

import { Button } from "components/Button";
import { EyeUnfilled } from "components/Icons";
import { Image } from "lib/imgproxy";
import { useQuery } from "@apollo/client";
import { TFunction } from "i18next";
import { UrlObject } from "url";
import { useBreakpoint } from "utils/breakpoints";

declare type Url = string | UrlObject;
const searchCentreCardQuery = graphql(`
    query searchCentreCardQuery {
        categories {
            uid
            name
        }
    }
`);

type CentreCardProps = {
    t: TFunction<["components/SearchPage", "common"]>;
    name: string;
    cover?: string;
    cityAndState?: string;
    bookNowHref: Url;
    href: Url;
    categoryId?: string | null;
    categoryIds: string[];
    isAvailable?: boolean;
    loading?: boolean;
};

const CentreCard = ({
    t,
    name,
    cover,
    cityAndState,
    bookNowHref,
    href,
    categoryIds,
    isAvailable = true,
    loading = false,
}: CentreCardProps): JSX.Element => {
    const { lg: isDesktop } = useBreakpoint();
    const { data } = useQuery(searchCentreCardQuery);
    const dCategories = data?.categories ?? [];
    const categoryNames = categoryIds
        .map((id) => dCategories.find((c) => c.uid === id)?.name)
        .filter((name) => name !== undefined)
        .join(", ");

    let actions;

    if (loading) {
        actions = (
            <nav className="grid grid-cols-1">
                <Button
                    className="tertiary"
                    size={isDesktop ? "md" : "sm"}
                    disabled
                >
                    {`${t("common:loading", "Loading")}...`}
                </Button>
            </nav>
        );
    } else if (isAvailable) {
        actions = (
            <nav className="grid grid-cols-2 gap-2">
                <Link href={href} passHref>
                    <Button
                        variant="tertiary"
                        size={isDesktop ? "md" : "sm"}
                        asChild
                    >
                        <a className="flex gap-2 no-underline">
                            <EyeUnfilled className="size-5" />
                            {t("common:buttonView", "View")}
                        </a>
                    </Button>
                </Link>
                <Link href={bookNowHref} passHref>
                    <Button
                        variant="secondary"
                        size={isDesktop ? "md" : "sm"}
                        asChild
                    >
                        <a className="flex gap-2 no-underline">
                            {t("common:buttonBookNow", "Book Now")}
                        </a>
                    </Button>
                </Link>
            </nav>
        );
    } else {
        actions = (
            <nav className="grid grid-cols-1">
                <Button
                    className="tertiary"
                    size={isDesktop ? "md" : "sm"}
                    disabled
                >
                    {t("noSlots", "No slots available")}
                </Button>
            </nav>
        );
    }

    return (
        <li className="flex cursor-pointer flex-col gap-2 rounded-xl border-[0.5px] border-solid border-blue-grey-50 bg-white pb-3 pt-3 transition-all lg:gap-5 lg:pb-4 lg:pt-0 lg:group-data-[filter=hide]:w-[332px] lg:group-data-[filter=show]:w-[320px]">
            <span className="typography-micro mx-4 mb-1 line-clamp-1 border-0 border-b border-solid border-blue-grey-20 uppercase text-blue-grey lg:hidden">
                {categoryNames}
            </span>
            <div className="mx-3 grid grid-cols-2 gap-2 lg:mx-0 lg:grid-cols-1">
                <div className="relative aspect-video w-full flex-1">
                    <Image
                        alt={`${name} cover image`}
                        src={cover || "/images/default_centre.jpg"}
                        layout="fill"
                        objectFit="cover"
                        unoptimized={!cover}
                        className="rounded lg:rounded-b-none lg:rounded-t-xl"
                        sizes="(max-width: 576px) 550px,(max-width: 768px) 750px, 400px"
                    />
                </div>
                <div className="flex-1 lg:mx-4 lg:mb-4">
                    <p className="typography-micro line-clamp-1 hidden truncate uppercase text-blue-grey lg:block">
                        {categoryNames}
                    </p>
                    <h4 className="typography-h4 line-clamp-2 font-semibold text-blue-grey-900 lg:line-clamp-1">
                        {name}
                    </h4>
                    <p className="typography-tiny line-clamp-2 text-blue-grey-500">
                        {cityAndState}
                    </p>
                </div>
            </div>
            <section className="mx-3 flex flex-1 flex-col justify-between gap-5 lg:mx-4">
                {actions}
            </section>
        </li>
    );
};

export { CentreCard };
