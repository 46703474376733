import { useMediaQuery } from "react-responsive";

export const useBreakpoint = (): { lg: boolean; md: boolean; sm: boolean } => {
    const sm = useMediaQuery({
        query: "(min-width: 576px)",
    });
    const md = useMediaQuery({
        query: "(min-width: 768px)",
    });
    const lg = useMediaQuery({
        query: "(min-width: 992px)",
    });
    return { lg, md, sm };
};
