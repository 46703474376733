import { ReactNode } from "react";
import { cx } from "class-variance-authority";

const styles = {
    rootContainer: "p-4 rounded-xl bg-white",
};

type SectionProps = {
    className?: string;
    children: ReactNode;
};

export const Section = ({ className, children }: SectionProps): JSX.Element => (
    <div className={cx(styles.rootContainer, className)}>{children}</div>
);
